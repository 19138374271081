import type { Ref } from 'vue'
import type { IDocument } from '@register'

export const usePublishAmendmentHandler = (
  document: Ref<IDocument | undefined>,
) => {
  const instance = getCurrentInstance()
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  const { mutateAsync: publishVariationDocument } =
    useApiLeaseVariationsPublish(divisionId, leaseId)

  return useConfirmHandler(
    () => publishVariationDocument({ variationDocumentId: document.value!.id }),
    {
      modal: {
        title: 'Publish amendment',
        body: 'Are you sure you want to publish this amendment?',
      },
      loadingMessage: 'Publishing...',
      successMessage: 'Amendment published',
      errorMessage: 'Failed to publish this amendment',
      onConfirm: () => {
        instance?.emit('publish')
      },
      submitHandlerOptions: {
        beforeSubmit: () => {
          if (!document.value) return false
        },
      },
    },
  )
}
