<script setup lang="ts">
import {
  useDeleteAmendmentHandler,
  usePublishAmendmentHandler,
  useUnlinkHandler,
  type ILeaseVariationDocument,
} from '@register'
import { useAmendmentFormModal } from '../composables/useAmendmentFormModal'

// PROPS
const props = defineProps<{ amendment: ILeaseVariationDocument }>()

// VARIATIONS
const variations = computed(() => props.amendment.variations ?? [])
const isEmptyVariation = computed(() => variations.value.length === 0)

// CREATE
const onCreate = useAmendmentFormModal(props.amendment)

// DELETE
const onDelete = useDeleteAmendmentHandler()

// PUBLISH
const onPublish = usePublishAmendmentHandler(toRef(props.amendment, 'document'))

// UNLINK
const onUnlink = useUnlinkHandler()
</script>

<template>
  <div
    class="group z-30 mb-4 mt-2 rounded-lg bg-gray-800 pb-2 ring-4 ring-transparent backdrop-blur-xl backdrop-saturate-[180%] hover:-z-50 hover:bg-gray-700/50 hover:ring-gray-400"
  >
    <AmendmentDocumentHeader
      :document="amendment.document"
      @publish="onPublish"
      @unlink="onUnlink"
      @add="onCreate"
    />

    <AmendmentDocumentEmpty
      v-if="isEmptyVariation"
      :amendment="amendment"
      @create="onCreate"
    />
    <AmendmentTable
      v-else
      :amendment="amendment"
      @create="onCreate"
      @delete="onDelete"
    />
  </div>
</template>
